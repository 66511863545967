import { Box, Divider, Typography } from '@mui/material';
import { APPLICANT_VALIDATION_VARIANTS_MAP } from 'app-sections/user/applications/steps/step2/config';
import QuestionAnswer from 'app-sections/user/applications/steps/step3/components/summary-sections/QuestionAnswer';
import { get } from 'lodash-es';
import { useLocation } from 'react-router-dom';
import { Application } from 'types';
import ExpandableSection from 'ui/components/expandable-section/ExpandableSection';
import SummaryTextGrid from 'ui/components/summary-text-grid/SummaryTextGrid';
import { formatPhoneNumber } from 'ui/helpers/maskedValues';
import { parseDate, UI_DATE_TIME_FORMAT_STRING } from 'ui/helpers/parseDate';
import { parseMoney } from 'ui/helpers/parseMoney';
import { applicationPublicView } from '../helpers';

export interface AuthorizationFormProps {
  data: Application;
}

export default function AuthorizationForm(props: AuthorizationFormProps) {
  const location = useLocation();
  const isPublicView = applicationPublicView(location);
  const { data } = props;

  return (
    <ExpandableSection title="Authorization form" defaultExpanded={true}>
      <Box my={4}>
        <Typography component="h3" variant="h3" sx={{ mb: 3 }}>
          Applicant Information
        </Typography>
        <SummaryTextGrid
          items={[
            {
              label: 'Applicant',
              desc: APPLICANT_VALIDATION_VARIANTS_MAP[data.whoApplying],
            },
            {
              label: 'Name',
              desc: get(data, 'applicantPersonalInformation.name') || '',
            },
          ].concat(
            isPublicView
              ? []
              : [
                  {
                    label: 'Email Address',
                    desc: get(data, 'applicantPersonalInformation.email') || '',
                  },
                  {
                    label: 'Mobile Phone Number',
                    desc: formatPhoneNumber(get(data, 'applicantPersonalInformation.phone') || ''),
                  },
                ]
          )}
        />
      </Box>

      <Box my={4}>
        <Typography component="h3" variant="h3" sx={{ mb: 3 }}>
          Property Owner Information
        </Typography>
        <SummaryTextGrid
          items={[
            {
              label: 'Name',
              desc: get(data, 'propertyOwnerInformation.name') || '',
            },
          ].concat(
            isPublicView
              ? []
              : [
                  {
                    label: 'Email Address',
                    desc: get(data, 'propertyOwnerInformation.email') || '',
                  },
                  {
                    label: 'Mobile Phone Number',
                    desc: formatPhoneNumber(get(data, 'propertyOwnerInformation.phone') || ''),
                  },
                ]
          )}
        />
      </Box>
      {data.paymentState === 'paid' && !isPublicView && (
        <Box my={4}>
          <Typography component="h3" variant="h3" sx={{ mb: 3 }}>
            Payment Information
          </Typography>
          <SummaryTextGrid
            items={[
              {
                label: 'Payment By',
                desc: get(data, 'clientProfile.fullName') || '',
              },
              {
                label: 'Payment Date',
                desc: parseDate(data.paidAt, UI_DATE_TIME_FORMAT_STRING) || '',
              },
              {
                label: 'Payment Details',
                desc: (
                  <div>
                    {data.permitRecordIds.map((permitNumber) => (
                      <div key={permitNumber}>
                        Application Fee ({permitNumber}) - {parseMoney(data.applicationFee)}
                      </div>
                    ))}
                    <div>
                      Enhanced Service Fee ({data.supplementalFeePercent * 100}%) -{' '}
                      {parseMoney(data.applicationFee * data.supplementalFeePercent * data.permitRecordIds.length)}
                    </div>
                    {Boolean(data.paymentGatewayFee) && (
                      <span>Paymentus Convenience Fee - {parseMoney((data.paymentGatewayFee || 0) * 100)}</span>
                    )}
                  </div>
                ),
              },
              {
                label: 'Total Amount Paid',
                desc: parseMoney(data.totalAmount + (data.paymentGatewayFee || 0) * 100),
              },
            ]}
          />
        </Box>
      )}
      <Box my={4}>
        <Divider sx={{ mb: 2 }} />
        <Typography component="h3" variant="h3" sx={{ mb: 3 }}>
          Application Information
        </Typography>

        <QuestionAnswer
          data={data}
          title="Subdivision"
          questionField={'anySubdivision'}
          questionText="Does the subject building permit require a property subdivision that is not completed at the time of this Wall Check authorization?"
          acknowledgementField={'isSubdivisionAccepted'}
          acknowledgementText="Applicant acknowledges that the Wall Check submission cannot qualify for Zoning review prior to the completion of the subdivision. At the time of submission of the Wall Check supporting documents, proof of a completed subdivision will be required."
        />
        <QuestionAnswer
          data={data}
          title="Unpermitted Projection or Encroachment"
          questionField={'anyEncroachment'}
          questionText="Does the application encroach onto neighboring public or private properties?"
          acknowledgementText={
            data.isEnchroachmentPrivate
              ? 'Applicant acknowledges that the Wall Check submission cannot qualify for Zoning review prior to the completion of an easement(s) demonstrating support for the encroachment. At the time of the submission of the Wall Check, the supporting document(s) will be required.'
              : ''
          }
          acknowledgementText2={
            data.isEnchroachmentPublic
              ? 'Applicant acknowledges that the Wall Check submission cannot qualify for Zoning review prior to the completion of an easement(s) demonstrating support for the encroachment. At the time of the submission of the Wall Check, the supporting document(s) will be required.'
              : ''
          }
          acknowledgementStatus={data.anyEncroachment || false}
          acknowledgementExtraText={
            data.isEnchroachmentPrivate ? 'The Unpermitted Projection or Encroachment is in private space' : ''
          }
          acknowledgementExtraText2={
            data.isEnchroachmentPublic ? 'The Unpermitted Projection or Encroachment is in public space' : ''
          }
        />
        <QuestionAnswer
          data={data}
          title="Below Grade Application"
          questionField={'isBellowGrade'}
          questionText="Is the submission for the purpose of a below grade Wall Check?"
          acknowledgementField={'isBellowGradeApplicationAccepted'}
          acknowledgementText="Applicant acknowledges that the Wall Check submission may only be submitted and recorded in the Surveyor’s Office as a supporting document for future “Above Grade” submissions."
        />
      </Box>

      <Divider />

      <Box my={4}>
        <Typography component="h3" variant="h3" sx={{ mb: 3 }}>
          Additional Stakeholders
        </Typography>
        {data.stakeholders &&
          data.stakeholders.map((stakeholder) => (
            <SummaryTextGrid
              key={stakeholder.email}
              items={[
                {
                  label: 'User Type / Role',
                  desc: APPLICANT_VALIDATION_VARIANTS_MAP[stakeholder.userType],
                },
                {
                  label: 'Name',
                  desc: get(stakeholder, 'name') || '',
                },
              ].concat(
                isPublicView
                  ? []
                  : [
                      {
                        label: 'Email Address',
                        desc: get(stakeholder, 'email') || '',
                      },
                      {
                        label: 'Mobile Phone Number',
                        desc: formatPhoneNumber(get(stakeholder, 'phone') || ''),
                      },
                    ]
              )}
            />
          ))}
      </Box>

      <Divider />

      <Box my={4}>
        <Typography component="h3" variant="h3" sx={{ mb: 3 }}>
          Notes
        </Typography>
        <p>{data.notes}</p>
      </Box>
    </ExpandableSection>
  );
}

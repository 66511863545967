import { ZoningReviewPermitProps } from 'app-sections/admin/applications/details/components/sections/review-details/components/review-details-tabs-content/zoning-review/helpers';
import { isNowBeforeSlaStartedAt } from 'app-sections/common/applications/helpers';
import { parseDocumentCollectionResponse } from 'core/api/documents/parsers';
import { useAuthStore } from 'core/store/auth';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Application } from 'types';
import FileItem from 'ui/components/file-item/FileItem';
import InfoCard from 'ui/components/info-card/InfoCard';
import InfoMessage from 'ui/components/info-message/InfoMessage';
import { isCompleted, isCompletedByFullStatus } from 'ui/helpers/applications';
import { parseDate, UI_DATE_TIME_FORMAT_STRING } from 'ui/helpers/parseDate';

import { isSurveyorReviewerForApplication } from 'ui/helpers/users';

import DownloadIcon from 'ui/icons/DownloadIcon';
import FoldersIcon from 'ui/icons/FoldersIcon';
import RankingIcon from 'ui/icons/RankingIcon';

import { Box, Card, CardContent, Divider, Grid } from '@mui/material';

import AdditionalSsls from '../additional-ssls/AdditionalSsls';
import ApplicationRemainingTimeCounter from '../remaining-time-counter/ApplicationRemainingTimeCounter';
import SecondaryAddress from '../secondary-address/SecondaryAddress';
import StatusStepper from '../status-stepper/StatusStepper';
import DivisionDates from './DivisionDates';

export interface GeneralInfoProps {
  application: Application;
  permits: ZoningReviewPermitProps[];
}

function authorizationFormList(application: Application) {
  const fileList = parseDocumentCollectionResponse(application.permitsDocuments || []);

  return Object.values(fileList)
    .map((document) =>
      Object.assign(document, {
        name: `Authorization Form ${document.objectId}`,
      })
    )
    .map((document) => {
      return (
        <div key={document.id}>
          <FileItem item={document} canRemoveFiles={false} isLoading={false} showIcon={false} />
        </div>
      );
    });
}

export function stepperStatusesForApplication(application: Application): {
  status: string;
  subStatus: string;
} {
  let status = 'pending',
    subStatus: string = 'pending_invitation';

  switch (application.state) {
    case 'pending':
      status = 'pending';
      subStatus = application.surveyorInviteStatus === 'accepted' ? 'pending_documents' : 'pending_invitation';
      break;
    case 'surveyor_office_review':
      status = 'surveyor_office_review';
      subStatus = 'in_progress';
      break;
    case 'zoning_review':
      status = 'zoning_review';
      subStatus = 'in_progress';
      break;
    case 'client_action_needed':
      status = application.currentDivision === 'surveyorsOffice' ? 'surveyor_office_review' : 'zoning_review';
      subStatus = 'hold_for_correction';
      break;
    case 'swo':
      status = 'swo';
      subStatus = 'swo';
      break;
    case 'completed':
    case 'completed_below_grade':
    case 'completed_partially':
    case 'denied':
    case 'deleted_by_client':
      status = 'closed';
      subStatus = {
        completed: 'approved',
        completed_below_grade: 'bellow_grade',
        denied: 'denied',
        completed_partially: 'partially_approved',
        deleted_by_client: 'cancelled',
      }[application.state];
  }

  return { status, subStatus };
}

const IN_REVIEW_STATES = ['surveyor_office_review', 'zoning_review', 'client_action_needed'];

export default function GeneralInfo(props: GeneralInfoProps) {
  const { application } = props;
  const [remainingTimeOverdue, setRemainingTimeOverdue] = useState<boolean>(false);
  const { currentUser } = useAuthStore();

  const handleExpiration = () => {
    if (!application.slaStoppedAt || dayjs(application.slaDeadlineAt).isBefore(application.slaStoppedAt)) {
      setRemainingTimeOverdue(true);
    }
  };

  const surveyorOfficeStartedOn = application.statuses.find(
    (status) => status.name === 'app.statuses.applications.surveyor_office_review'
  )?.createdAt;
  const zoningOfficeStartedOn = application.statuses.find(
    (status) => status.name === 'app.statuses.applications.zoning_review'
  )?.createdAt;
  const surveyorOfficeCompletedOn = zoningOfficeStartedOn;
  const lastStatus = application.statuses.slice(-1)[0];
  const zoningOfficeCompletedOn =
    (lastStatus && isCompletedByFullStatus(lastStatus) && lastStatus.createdAt) || undefined;
  const stepperStatus = stepperStatusesForApplication(application);
  const applicationCompleted = isCompleted(application);
  const slaNotStarted = isNowBeforeSlaStartedAt(application);

  const canEditAdditionalInfo = isSurveyorReviewerForApplication(currentUser, application);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} tablet={7} lg={7}>
        <InfoCard
          label="Authorization created on"
          info={parseDate(application.paidAt, UI_DATE_TIME_FORMAT_STRING)}
          icon={<FoldersIcon sx={{ fontSize: '3rem' }} />}
        />

        <div id="download-form-section">
          <InfoCard
            styled={false}
            label="Downloadable authorization form"
            info={<Box mt={2}>{authorizationFormList(application)}</Box>}
            icon={<DownloadIcon sx={{ fontSize: '3rem' }} />}
          />
        </div>

        <InfoCard
          styled={false}
          label="Additional information (OPTIONAL)"
          info={
            <Box mt={2}>
              <SecondaryAddress
                title="Secondary Address"
                parentId={application.id}
                isEditable={canEditAdditionalInfo}
                items={application.additionalAddresses || []}
              />
              <Divider />
              <AdditionalSsls
                title="Additional SSL"
                parentId={application.id}
                items={application.additionalSsls || []}
                isEditable={canEditAdditionalInfo}
              />
            </Box>
          }
          icon={<RankingIcon sx={{ fontSize: '3rem' }} />}
        />

        <InfoCard
          label="DOB Review progress"
          styled={false}
          info={
            <>
              <DivisionDates
                label="Surveyor’s Office review"
                startedOn={surveyorOfficeStartedOn}
                completedOn={surveyorOfficeCompletedOn}
                completed={!!surveyorOfficeCompletedOn}
              />
              <DivisionDates
                label="Zoning review"
                startedOn={zoningOfficeStartedOn}
                completedOn={zoningOfficeCompletedOn}
                completed={!!zoningOfficeCompletedOn}
              />
            </>
          }
          icon={<RankingIcon sx={{ fontSize: '3rem' }} />}
        />

        {IN_REVIEW_STATES.includes(application.state) && (
          <>
            {slaNotStarted && (
              <InfoMessage
                type="neutral"
                notClosable={true}
                isSlim={true}
                title=""
                content="Estimated Remaining Review Time’ counter starts at 09:00 AM on the day after DC Registered Surveyor has uploaded the necessary documents."
              />
            )}
            {!slaNotStarted && (
              <InfoCard
                label={remainingTimeOverdue ? 'Overdue' : 'Estimated Remaining Review Time (Business Day(s))'}
                info={
                  <>
                    {!applicationCompleted && application.slaDeadlineAt && (
                      <ApplicationRemainingTimeCounter application={application} onExpiration={handleExpiration} />
                    )}
                    {(!application.slaDeadlineAt || applicationCompleted) && '-'}
                  </>
                }
                icon={<RankingIcon sx={{ fontSize: '3rem' }} />}
                isWarning={remainingTimeOverdue}
              />
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} tablet={5} lg={5}>
        <Card className="rounded-small small-margin-bottom">
          <CardContent>
            <Box
              textTransform="uppercase"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '2rem',
                color: '#213A6D',
              }}
            >
              Application progress
            </Box>
            {application.state !== 'swo' && (
              <StatusStepper status={stepperStatus.status} subStatus={stepperStatus.subStatus} />
            )}
            {application.state === 'swo' && (
              <Box
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                }}
              >
                Application is blocked due to Hold/Lock
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

import './sidebar-layout.css';

import { Outlet } from 'react-router-dom';

import LayoutErrorBoundary from '../../../errors/LayoutErrorBoundary';
import AppToolbar from '../app-toolbar/AppToolbar';

export default function NoSidebarLayout() {
  return (
    <div className="root">
      <div className="layout-wrapper">
        <main className="content static-toolbar" id="main-scroll-container">
          <AppToolbar />
          <LayoutErrorBoundary>
            <Outlet />
            {/* <CustomRoutesProvider mobileAnimationType="opacity" /> */}
          </LayoutErrorBoundary>
        </main>
      </div>
    </div>
  );
}

import { getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ApplicationPaymentState } from 'types';
import StatusCard, { StatusCardState } from 'ui/components/status-card/StatusCard';
import { scrollToSectionId } from 'ui/helpers/scrollToSectionId';

import { Button } from '@mui/material';

const getCardState = (type: ApplicationPaymentState): StatusCardState => {
  if (type === 'failed') {
    return 'failed';
  }

  if (type === 'paid') {
    return 'success';
  }

  return 'pending';
};

const getTitle = (type: ApplicationPaymentState): string => {
  if (type === 'failed') {
    return 'Failed payment';
  }

  if (type === 'paid') {
    return 'Successful payment';
  }

  return 'Payment processing';
};

const getContent = (type: ApplicationPaymentState): string | JSX.Element => {
  if (type === 'failed') {
    return (
      <>
        <p>Your payment attempt failed for some reason. Please try again after checking the following:</p>
        <ul>
          <li>Ensure your payment method is valid and has sufficient funds.</li>
          <li>Verify that all payment details (card number, expiration date, and security code) are correct.</li>
        </ul>
        <p>
          If the issue persists, <a href="mailto:dob@dc.gov">contact us</a> for support.
        </p>
      </>
    );
  }

  if (type === 'paid') {
    return 'Payment has been successfully processed. The Authorization Form is accepted and the Wall Check application number is created.';
  }

  return (
    <>
      Processing payment may take up a few minutes in case of using a card. ACH payments made from a bank account will
      take 5 days to settle.
    </>
  );
};

const getButtonForStatus = (
  status: ApplicationPaymentState,
  applicationId: string,
  navigate: NavigateFunction
): JSX.Element | null => {
  switch (status) {
    case 'failed':
      return getButton('Try again', () => scrollToSectionId('payments-section'));
    case 'paid':
      return getButton('Download form', () => {
        navigate(getLinkToDetails(applicationId, 'summary?payment_status=success#download-form-section'));
        scrollToSectionId('download-form-section');
      });
  }
  return null;
};

const getButton = (label: string, onClickHandle: () => void): JSX.Element | null => {
  return (
    <Button type="button" variant="contained" color="primary" disableElevation onClick={onClickHandle}>
      {label}
    </Button>
  );
};

export interface PaymentStatusInfoProps {
  status: ApplicationPaymentState;
  applicationId: string;
}

export default function PaymentStatusInfo(props: PaymentStatusInfoProps) {
  const { status, applicationId } = props;
  const navigate = useNavigate();

  return (
    <StatusCard
      title={getTitle(status)}
      content={getContent(status)}
      isLoading={status === 'pending'}
      status={getCardState(status)}
      button={getButtonForStatus(status, applicationId, navigate)}
    />
  );
}

import StepsHeader from 'app-sections/user/applications/steps/components/steps-header/StepsHeader';
import { Outlet } from 'react-router-dom';

export default function ApplicationStepsLayout() {
  return (
    <>
      <StepsHeader />
      <Outlet />
    </>
  );
}

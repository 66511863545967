import { ZoningReviewPermitProps } from 'app-sections/admin/applications/details/components/sections/review-details/components/review-details-tabs-content/zoning-review/helpers';
import { Application, ApplicationState } from 'types';

export const countProgress = (
  application: Application,
  permits: ZoningReviewPermitProps[]
): number => {
  return countAppProgress(application) + permitsProgress(permits);
};

export const STATE_LABELS: Record<
  ApplicationState | 'pending_invitation' | 'pending_no_pending_invitation',
  string
> = {
  draft: 'Project Creation',
  pending_payment: 'Payment in progress',
  pending_invitation: 'Pending Invitation',
  paid: 'Paid',
  pending: 'Review is Pending',
  pending_no_pending_invitation: 'Review is Pending',
  surveyor_office_review: 'Surveyor’s Office Review',
  zoning_review: 'Zoning Review',
  completed: 'Approved',
  completed_below_grade: 'Below Grade',
  completed_partially: 'Partially approved',
  denied: 'Denied',
  deleted_by_client: 'Cancelled',
  swo: 'Hold or Lock Restriction',
  client_action_needed: 'Hold for correction',
};

const countAppProgress = (application: Application): number => {
  let progress = 0;

  switch (application.state) {
    case 'pending':
      progress = 10;
      break;
    case 'surveyor_office_review':
    case 'client_action_needed':
      progress = 30;
      break;
    case 'zoning_review':
      progress = 60;
      break;
    case 'completed_partially':
    case 'completed_below_grade':
    case 'denied':
    case 'completed':
      progress = 60;
      break;
  }
  return progress;
};

const permitsProgress = (permits: ZoningReviewPermitProps[]): number => {
  const finishedPermitsCount = permits.filter(
    (permit) =>
      permit.zoningReviews.length > 0 &&
      ['approved', 'rejected'].includes(permit.zoningReviews[0].state)
  ).length;
  const permitsProgress =
    finishedPermitsCount === 0 ? 0 : finishedPermitsCount / permits.length;

  return Math.floor(40 * permitsProgress);
};

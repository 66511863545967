import PaymentTable from 'app-sections/common/applications/details/components/sections/payment/payment-table/PaymentTable';
import AuthorizationForm from 'app-sections/common/applications/summary/AuthorizationForm';
import Permits from 'app-sections/common/applications/summary/Permits';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { useSearchParams } from 'react-router-dom';
import { Application } from 'types';
import FormSectionHeader from 'ui/form-section-header/FormSectionHeader';

import { Box, Card, CardContent, Typography } from '@mui/material';

import BackButton from '../components/buttons/BackButton';
import { getLinkToStep } from '../config';
import PayButtonSwoCheck from './components/pay-button-swo-check/PayButtonSwoCheck';

export interface Step3ContentProps {
  applicationData: Application;
}

export const APPLICATION_FEE = 50.0;
export const SUPPLEMENTAL_FEE = 10.0;

export default function Step3Content(props: Step3ContentProps) {
  const { applicationData } = props;
  const { activeApplicationId, hasSwoError } = useApplicationsStore();
  const { currentUser } = useAuthStore();

  const [searchParams] = useSearchParams();

  const payButtonDisabled =
    ['pending', 'paid'].includes(applicationData.paymentState || '') ||
    (searchParams.get('payment_status') === 'check' && applicationData.paymentState !== 'failed') ||
    hasSwoError;
  const paymentLocked = applicationData.paymentState === 'new';

  //  Only user that created the application can pay. It may be client or dc_registered_surveyor
  const payButtonVisible = currentUser?.id === applicationData.clientId;

  return (
    <>
      <Permits data={applicationData} />
      <AuthorizationForm data={applicationData} />
      <Box>
        <div id="payments-section">
          <FormSectionHeader title="Payment" subtitle="Check payment details and pay fee for Wall Check." />
          <Card>
            <CardContent>
              <Typography component="h3" variant="h3">
                Payment info
              </Typography>
              <p>The application is submitted once you have paid the application/recordation fee.</p>
              {/* <p>ACH payments made from a bank account take 5 days to settle.</p> */}
              <p>
                <strong>Payment fees</strong>
                <br />
                The application/recordation fee is {APPLICATION_FEE}$ per lot + {SUPPLEMENTAL_FEE}% enhanced service
                fee.
                <br />
                Click on the checkout button below to proceed.
              </p>
              <p>Payment completed at:</p>
              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                <Box
                  width={{ xs: '100%', tablet: '50%' }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <PaymentTable data={applicationData} isPaid={false} />
                </Box>
              </Box>

              {payButtonVisible && (
                <>
                  <PayButtonSwoCheck
                    applicationId={activeApplicationId}
                    text="Check out and pay"
                    color="primary"
                    disabled={payButtonDisabled || paymentLocked}
                    permitRecordIds={applicationData.permitRecordIds || []}
                  />

                  <Box mt={1} display="flex" justifyContent="flex-end">
                    {paymentLocked && <>You have already started payment session.</>}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </Box>

      {applicationData.paymentState === 'pending' && (
        <Box my={4} justifyContent="space-between">
          <BackButton isLoading={false} backButtonLink={getLinkToStep(activeApplicationId, 2)} />
        </Box>
      )}
    </>
  );
}

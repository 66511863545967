import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useLocation } from 'react-router-dom';

import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import HorizontalStepper from '../stepper/Stepper';
import SwoWrapper from '../swo-wrapper/SwoWrapper';
import TitleHeader from './TitleHeader';

export default function StepsHeader() {
  const { activeApplicationId, activeStep, latestValidStep } = useApplicationsStore();

  const location = useLocation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const applicationQueryKey = ['applications', activeApplicationId];
  const { data: applicationData } = useQuery({
    queryKey: applicationQueryKey,
    queryFn: () => getApplicationById(activeApplicationId),
    enabled: activeApplicationId !== '',
  });

  // Special layout for payment page
  const isPaymentPage = location.pathname.includes('payment/pay');

  return (
    <Box mt={5}>
      <Container className="disable-center">
        <TitleHeader isSmall={isPaymentPage} />
        {!isPaymentPage && (
          <Box mt={6} mb={matches ? 4 : 0}>
            <SwoWrapper />
            <HorizontalStepper
              activeStep={activeStep}
              latestValidStep={latestValidStep}
              disabled={applicationData?.state === 'pending_payment' || activeApplicationId === ''}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
}

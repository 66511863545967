import Error404Page from 'core/layouts/error-pages/Error404Page';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

const PaymentPage = lazy(() => import('app-sections/user/applications/steps/components/payment/PaymentPage'));

export const applicationPaymentRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate replace to="pay" />,
  },
  {
    path: 'pay',
    element: (
      <Suspense fallback={<DataLoader />}>
        <PaymentPage />
      </Suspense>
    ),
  },
  {
    path: '*',
    element: <Error404Page />,
  },
  {
    path: ':any/*',
    element: <Error404Page />,
  },
];

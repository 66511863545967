import { Application } from 'types';
import InfoMessage from 'ui/components/info-message/InfoMessage';

import { Box } from '@mui/material';

export function generateMailtoLink(data: Application | undefined): string {
  if (!data) {
    return 'mailto:Dob@dc.gov?subject=Hold or Lock Restriction';
  }

  const fullAddress = `Hold or Lock Restriction ${data.permitAddress}`;
  const encodedAddress = encodeURIComponent(fullAddress);
  return `mailto:Dob@dc.gov?subject=${encodedAddress}`;
}

export interface SwoInfoProps {
  data: Application | undefined;
}

export default function SwoInfo(props: SwoInfoProps) {
  const { data } = props;
  return (
    <InfoMessage
      type="warning"
      notClosable={true}
      title="There is a Hold/Lock restriction in the address"
      content={
        <>
          <p>
            A Hold/Lock restriction has been placed on this address or on an associated permit. Restriction description:{' '}
            {data?.swoConditions?.join('; ')}
            <br />
            Please contact{' '}
            <strong>
              <a href={generateMailtoLink(data)}>DOB at DOB@dc.gov</a>
            </strong>{' '}
            or{' '}
            <Box component="strong" sx={{ whiteSpace: 'nowrap' }}>
              202-671-3500
            </Box>{' '}
            for further information.
          </p>
          <p>
            You may continue the application process, but it will remain in draft status and cannot be submitted until
            the restriction has been resolved. At that time, you will receive notification and you may proceed with your
            application.
          </p>
        </>
      }
    />
  );
}

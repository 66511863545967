import DebugLink from 'app-sections/common/applications/details/components/details-header/components/debug-link/DebugLink';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

interface TitleHeaderProps {
  isSmall?: boolean;
}

export default function TitleHeader(props: TitleHeaderProps) {
  const { isSmall } = props;
  const theme = useTheme();

  const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Typography variant="h1" component="h1" className="with-indicator-red">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          fontSize: matchesMobile || isSmall ? '3rem' : '4rem',
        }}
      >
        Authorization form for Wall Check <DebugLink />
      </Box>
    </Typography>
  );
}

import ApplicationPaymentLayout from 'core/layouts/components/application-payment-layout/ApplicationPaymentLayout';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';

import ApplicationsPaymentResolver from '../protected-routes/applications-resolver/ApplicationsPaymentResolver';
import { applicationPaymentRoutes } from './applicationPaymentRoutes';

const Step1 = lazy(() => import('app-sections/user/applications/steps/step1/Step1'));
const Step2 = lazy(() => import('app-sections/user/applications/steps/step2/Step2'));
const Step3 = lazy(() => import('app-sections/user/applications/steps/step3/Step3'));

export const applicationStepsRoutes: RouteObject[] = [
  {
    path: '',
    element: <Navigate replace to="1" />,
  },
  {
    path: '1',
    element: (
      <Suspense fallback={<DataLoader />}>
        <Step1 />
      </Suspense>
    ),
  },
  {
    path: '2',
    element: (
      <Suspense fallback={<DataLoader />}>
        <Step2 />
      </Suspense>
    ),
  },
  {
    path: '3',
    element: (
      <Suspense fallback={<DataLoader />}>
        <Step3 />
      </Suspense>
    ),
  },
  {
    path: '3/payment',
    element: (
      <ApplicationsPaymentResolver>
        <ApplicationPaymentLayout />
      </ApplicationsPaymentResolver>
    ),
    children: applicationPaymentRoutes,
  },
  {
    path: '*',
    element: <Navigate replace to="/404" />,
  },
  {
    path: ':any/*',
    element: <Navigate replace to="/404" />,
  },
];

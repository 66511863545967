import { getConditionsInfo } from 'core/api/accela';
import { useApplicationsStore } from 'core/store/applications';
import { getPayUrl } from 'core/api/applications';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import ButtonLoader from 'ui/components/button-loader/ButtonLoader';
import { ConfirmationDialog } from 'ui/components/confirmation-dialog/ConfirmationDialog';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import InfoMessage from 'ui/components/info-message/InfoMessage';
import { PAY_PAGE } from 'ui/components/pay-button/PayButton';

import { Box, Button, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { isPermitWithSwo } from '../../../components/conditions-check/helpers';

export interface PayButtonSwoCheckProps {
  applicationId: string;
  text?: string;
  color?: 'secondary' | 'primary';
  disabled?: boolean;
  permitRecordIds: string[];
  url?: string;
}

export default function PayButtonSwoCheck(props: PayButtonSwoCheckProps) {
  const { text = 'Pay', color = 'secondary', applicationId, disabled, permitRecordIds, url = PAY_PAGE } = props;
  const { setSwoError } = useApplicationsStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<number>(0);

  const [dialogState, setDialogState] = useState<boolean>(false);
  // const [redirect, setRedirect] = useState<string>('');
  // const navigate = useNavigate();

  const {
    isLoading: isConditionsLoading,
    isError: isConditionsError,
    error: conditionsError,
    isSuccess: isConditionsSuccess,
    isRefetching: isConditionsRefetching,
    data: conditionsData,
  } = useQuery({
    queryKey: ['accela/conditions-info', JSON.stringify(permitRecordIds)],
    queryFn: ({ signal }) => {
      return getConditionsInfo(permitRecordIds, signal);
    },
    enabled: Boolean(permitRecordIds?.length) && Boolean(isSubmitting > 0),
    gcTime: 3600,
  });

  const handlePay = async () => {
    setIsLoading(true);
    const payData = await getPayUrl(applicationId, null);

    window.location.href = payData.payURL;
    setIsLoading(false);
  };

  useEffect(() => {
    if (isConditionsSuccess && conditionsData) {
      const swoSetAt = isPermitWithSwo(conditionsData);
      const checkResult = Boolean(swoSetAt);
      setSwoError(checkResult);
      setDialogState(checkResult);
      if (!checkResult) {
        handlePay();
      }
    }
  }, [isConditionsSuccess]);

  // useEffect(() => {
  //   if (redirect !== '') {
  //     navigate(redirect);
  //     setRedirect('');
  //   }
  // }, [redirect, navigate]);

  const isAnyLoading = isConditionsLoading || isConditionsRefetching;

  return (
    <>
      {(isConditionsLoading || isConditionsRefetching) && (
        <Box mt={2}>
          <InfoMessage
            type="info"
            title="Checking for Hold/Lock address restrictions"
            content="This may take a while."
            notClosable={true}
            isWithLoader={true}
          />
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button
          color={color}
          variant="contained"
          disableElevation
          disabled={disabled || isAnyLoading}
          onClick={() => {
            setIsSubmitting((prev) => prev + 1);
          }}
        >
          <ButtonLoader isActive={isAnyLoading} />
          {text}
        </Button>
      </Box>

      {isConditionsError && conditionsError && (
        <Box mt={2}>
          <ErrorsList title="Unexpected loading error" errors={conditionsError} />
        </Box>
      )}
      <ConfirmationDialog
        title={
          <Box mt={2} textAlign="center" color="main.dark">
            <Typography component="h2" variant="h2" sx={{ color: '#b05e11' }}>
              Application Submission Delayed
            </Typography>
          </Box>
        }
        description={
          <Box mb={2} fontSize={'1.8rem'}>
            You cannot proceed with the payment and submit your application due to an Hold/Lock restriction placed on
            the address or associated permit(s). It will remain in draft status until the Hold/Lock restriction is
            lifted. You will receive a notification once the restriction is resolved and you can proceed with payment
            and submitting your application.
            <br />
            <br />
            Contact the agency for further information and instructions.
          </Box>
        }
        opened={dialogState}
        confirmButtonVariant="contained"
        confirmButtonClass="primary"
        onConfirm={() => {
          setDialogState(false);
        }}
        confirmButtonText="OK"
        centeredButtons={true}
      />
    </>
  );
}
